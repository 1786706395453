import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateCompare'
})
export class DateComparePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return moment(value * 1000) >= moment();
  }

}
