/**
 * Copyrights Reserved 2023 SPARK Technologies!
 *
 * @author SPARK Technologies
 * @category Login
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.1.0
 *
 */

import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ActivatedRoute, ChildActivationEnd, Params, Router } from '@angular/router';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { distinctUntilChanged, Subject, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonApiService } from 'src/app/core/common-service/common-api.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import * as CryptoJS from 'crypto-js';
import { Auth, signInWithPopup, signInWithCustomToken, signOut, GoogleAuthProvider } from '@angular/fire/auth';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';
import { ChangeDetectionStrategy } from '@angular/compiler';
import { MatDialog } from '@angular/material/dialog';
import { RoleVerifyModalComponent } from './role-verify-modal/role-verify-modal.component';
import { LocationService } from 'src/app/core/location/location.service';
import { NgOtpInputComponent } from 'ng-otp-input';
import { CountdownComponent } from 'ngx-countdown';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  otpCtrl: FormControl = new FormControl('', [Validators.required, Validators.minLength(6)])

  portal: any = '';
  redirectUrl: any = '/';
  config: any;
  product: any;
  redirectPageUrl;
  registerPageUrl = "/register/2";
  locationInfo: any;
  userDetailsKey;
  userLoginKey;
  otpKey: any;
  mobile: any;
  timer: any = 5;

  personalKey = this.utilityService.encrypt("personal")
  businessKey = this.utilityService.encrypt("business")

  accountList: any[] = []
  accountListByRoles: any[] = []

  isPickup = false
  isBadRequest = false
  isLoading = false;
  passwordHide = true;
  verificationStep = false;
  isOTPExpires = false
  isLogging = false
  isSendingOTP: boolean;
  isOTPSent: boolean;
  isResendError: boolean;
  isVerifyingOTP: boolean;
  roleId: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;

  constructor(
    private _toastr: ToastrService,
    public _configService: SparkConfigService,
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _commonApiService: CommonApiService,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _activeRoute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private utilityService: UtilityService,
    private _locationService: LocationService,
    private _translocoService: TranslocoService,
    private auth: Auth,
    private _matDialog: MatDialog
  ) {

    this._activeRoute.queryParams
      .subscribe(
        (params: Params) => {
          if (params['portal']) {
            this.portal = params['portal'];
            this.ngxService.startLoader('loader-01');
            this._commonApiService.verifyService(this.portal).then((data) => {
              this.isBadRequest = data ? false : true
              if (data) {
                this.product = data
              }

            }).catch(() => {
              this.isBadRequest = true
            })
              .finally(() => {
                this.ngxService.stopLoader('loader-01');
              })
          }
        }
      );

    // this._activeRoute.queryParams
    //   .subscribe(
    //     (params: Params) => {
    //       if (params['login']) {
    //         this.roleId = params['login'] == this.personalKey ? 2 : 1
    //       }
    //     })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    let creds = this.utilityService.decryptAES(
      this.utilityService.getCookie("personalSecret"))
    try {
      let data = JSON.parse(creds)
      for (let key in data) {
        this.accountList.push({ ...data[key] })
        if (this.roleId && data[key].role == this.roleId) {
          this.accountListByRoles.push({ ...data[key] })
        }
      }
      if (!this.roleId && this.accountList.length > 0 || this.roleId && this.accountListByRoles.length > 0) {
        this.isPickup = true
      }
    }
    catch (e) {
      // return false;
    }

    this.loginForm = this._fb.group({
      'username': ['', Validators.required],
      'latitude': [''],
      'longitude': [''],
    })

    this._locationService.getPosition().then((result) => {
      if (result) {
        this.locationInfo = result
        this.loginForm.get("longitude")?.patchValue(this.locationInfo?.longitude)
        this.loginForm.get("latitude")?.patchValue(this.locationInfo?.latitude)
      }
    })

    if (!this.portal) {
      let apiUrl = environment.APIMainUrl
      this._configService.config = { apiUrl };
    }

    this._activeRoute.queryParams.subscribe(params => {
      if (params['verified'] && atob(params['verified']) == '1') {
        Swal.fire({
          heightAuto: false,
          icon: 'success',
          title: "Email verified successfully!"
        }).then((result: SweetAlertResult) => {
          this.router.navigate([], {
            queryParams: {
              'verified': null
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
          })
        })
      }
      else if (params['verified'] && atob(params['verified']) == '0') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          confirmButtonText: 'Send verify link again',
          title: "Email verification failed!"
        }).then((result: SweetAlertResult) => {
          this.router.navigate([], {
            queryParams: {
              'verified': null
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
          })
        })
      }
    });

    this._configService.config$.pipe(distinctUntilChanged()).subscribe((config: any) => {
      this.config = config;
      if (!this.portal) {
        //ocalStorage.setItem('config', JSON.stringify(this.config))
      }
      let params = this._activeRoute.snapshot.queryParams;
      if (params['reference']) {
        this.redirectUrl = params['reference'];
      }
      else {
        this.redirectUrl = environment.myaccountDomain;
      }
    });

    this.loginForm.markAsUntouched()
  }

  onDestroy() {
    this._unsubscribeAll.unsubscribe()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Events
  // -----------------------------------------------------------------------------------------------------

  stepChanged(event, stepper) {
    stepper.selected.interacted = false;
    this.loginForm.reset()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Operations
  // -----------------------------------------------------------------------------------------------------

  login(): any {
    this.isLoading = true;
    this._authService.signIn(this.loginForm.value).subscribe((data) => {
      //this.isLoading = false;
      if (data.status_code == 200) {
        this.verificationStep = true
        this.otpKey = data.data.otp_key
        this.userDetailsKey = data.data.user_details_key
        this.userLoginKey = data.data.user_login_key
        this._changeDetectorRef.detectChanges()
        this.countdown.begin()
        if (!environment.production)
          this.ngOtpInput.setValue(data.data.verification_code)
      }
      else {
        this.isLoading = false
        this.isLogging = false
      }
    },
      ((err) => {
        this.isLogging = false
        this.isLoading = false
      }),
      (() => {
        this.isLoading = false;
      })
    )
  }

  loginWithGoogle() {
    signInWithPopup(this.auth, new GoogleAuthProvider()).then((result: any) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      let loginData = {
        'latitude': this.locationInfo?.latitude,
        'longitude': this.locationInfo?.longitude,
        'accessToken': credential?.accessToken
      }
      //this.ngxService.startLoader('loader-01')
      this._authService.checkAccount(result?.user.email)
        .then((data: any) => {
          this.isLoading = true
          if (!data) {
            const dialogRef = this._matDialog.open(RoleVerifyModalComponent, {
              data: {},
              width: '450px',
              disableClose: true
            });

            dialogRef.afterClosed().subscribe(data => {
              if (data.status = 'approve') {
                result.user_role_drc_key = data.role
                let req = result
                this.googleSignIn({
                  ...req,
                  ...loginData
                })
              }
            });
          }
          else {
            this.googleSignIn({
              ...result,
              ...loginData
            })
          }
        })
    });
  }

  googleSignIn(req) {
    this._authService.signInWithGoogle(req)
      .subscribe((data) => {
        if (data.success == 1) {
          if (data.status_code == 200) {
            let creds = this.utilityService.getCookie("personalSecret")
            let accData = JSON.parse(this.utilityService.decryptAES(creds))
            let index = accData.findIndex((obj) => obj.username == req.user.email);
            var newUrl = new URL(this.redirectUrl);
            newUrl.searchParams.set("accIndex", (index >= 0 ? index : 0));
            window.location.replace(newUrl.href)
          }
        }
        else {
          this.isLoading = false;
          signOut(this.auth)
        }
      }, (err) => {

      }, () => {
        this.ngxService.stopLoader('loader-01')
      })
  }

  removeAccount(i) {
    let creds = this.utilityService.decryptAES(
      this.utilityService.getCookie("personalSecret"))
    let accData = JSON.parse(creds)
    let username = accData[i].username;
    let index = this.accountListByRoles.findIndex((acc) => acc.username == username)
    this.accountListByRoles.splice(index, 1)
    accData.splice(i, 1)
    this.accountList.splice(i, 1)
    this.utilityService.clearCookie(username + "_" + "profile_token")
    if (accData.length == 0) {
      this.utilityService.clearCookie("personalSecret")
    }
    else
      this.utilityService.setCookie("personalSecret", JSON.stringify(accData))
    if ((!this.roleId && this.accountList.length > 0) || this.roleId && this.accountListByRoles.length > 0) {
      this.isPickup = true
    }
    else
      this.isPickup = false
  }
  /**
   * Re Send OTP
   */
  resendOTP() {
    this.isSendingOTP = true
    this.isOTPExpires = false
    this.ngOtpInput.setValue('')

    let data = {
      'user_details_key': this.userDetailsKey,
      'otp_type': 2,
    }
    this._authService.resendRegisterOTP(data).then((data: any) => {
      if (data.data) {
        this.isOTPSent = true
        this.isResendError = false
        this.otpKey = data.data.otp_key
        this.countdown.begin();
        if (!environment.production)
          this.ngOtpInput.setValue(data.data.verification_code)
      } else {
        this.utilityService.showFailure(data.message)
        this.isResendError = true
        this.isOTPSent = false
      }
    }).finally(() => {
      this.isSendingOTP = false
    }).catch((error) => {
      this.isResendError = true
      this.isOTPSent = false
    })
  }

  /**
   * Verify OTP
   */
  verifyOTP() {
    this.isVerifyingOTP = true
    let data = {
      'verification_code': this.otpCtrl.value,
      'mobile_no': this.loginForm.get('username')?.value,
      'username': this.loginForm.get('username')?.value,
      'user_details_key': this.userDetailsKey,
      'user_login_key': this.userLoginKey,
      'otp_key': this.otpKey,
      'otp_type': 2,
      'latitude': this.locationInfo?.latitude ?? "",
      'longitude': this.locationInfo?.longitude ?? ""
    }
    this._authService.verifyOTP(data).then((data: any) => {
      if (data.success == 1) {
        this.otpKey = ''
        this.userDetailsKey = ''
        this.userLoginKey = ''
        if (!this.isOTPExpires)
          this.countdown.stop()
        this.isLogging = true
        let creds = this.utilityService.getCookie("personalSecret")
        let accData = JSON.parse(this.utilityService.decryptAES(creds))
        let index = accData.findIndex((obj) => obj.username == this.loginForm.value.username);
        var newUrl = new URL(this.redirectUrl);
        newUrl.searchParams.set("accIndex", (index >= 0 ? index : 0));
        window.location.replace(newUrl.href)
      }
      else {
        this.isLogging = false
        this.utilityService.showFailure(data.message)
      }
    }).finally(() => {
      this.isVerifyingOTP = false
    }).catch((error) => {
      console.log(error);
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Utility
  // -----------------------------------------------------------------------------------------------------
  goToDestination(index, expires) {
    if (moment(expires * 1000) <= moment()) {
      this.sessionDialog(index)
    }
    else {
      var newUrl = new URL(this.redirectUrl);
      newUrl.searchParams.set("accIndex", index);
      window.location.replace(newUrl.href)
    }
  }

  sessionDialog(index) {
    Swal.fire({
      title: this._translocoService.translate('auth.messages.error.account_is_logged_out'),
      icon: 'warning',
      confirmButtonText: this._translocoService.translate('auth.title.remove_account'),
      confirmButtonColor: 'red',
      cancelButtonColor: '#dadada',
      heightAuto: false
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        this.removeAccount(index)
        this.isPickup = false
      }
    })
  }

  handleEvent(event) {
    if (event.action == 'done') {
      this.isOTPExpires = true
    }
  }

}
